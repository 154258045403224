<script setup lang="ts">
useHead({
  title: "anybill",
});
definePageMeta({
  layout: "empty",
});
</script>

<template>
  <div class="page">
    <div class="content">
      <v-col>
        <v-img
          src="/public/anybill-logo.svg"
          class="error-logo ma-4"
        />
        Partner Portal
      </v-col>
      <v-col>
        <v-progress-circular
          indeterminate
          class="mt-4"
        />
      </v-col>
    </div>
  </div>
</template>

<style>
.page {
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  text-align: center;
  max-width: 500px;
  width: 80%;
  padding: 50px 0;
  border-radius: 8px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
  /* Soft shadow */
}
</style>
